#navbarContainer{
    width: fit-content;
    margin: auto;

    @media screen and (max-width: 800px){
        white-space: nowrap;
    }

    a{
        button{
            font-family: finland;
            background-color: #101010;
            color: white;
            font-size: 2.5rem;
            font-weight: 700;
            border: 3px solid white;
            border-radius: 5px;
            margin: 5rem 2rem 3rem 2rem;
            padding: 1rem;
            cursor: pointer;
            

            @media screen and (max-width: 800px){
                font-size: 2rem;
            }

            @media screen and (max-width: 620px){
                font-size: 5.5vw;
                border: 0.5vw solid white;
                margin: 2vw 4vw;
                padding: 2.5vw;
            }

            @media screen and (max-width: 550px){
                font-size: 7.5vw;
                margin: 2vw;
                padding: 3.2vw;
            }

            &:hover, &:focus, &:active{
                background-color: white;
                color: #101010; 
            }
        }

        #projectsLink:hover{
            border: 3px solid #FFB866;

            @media screen and (max-width: 620px){
                border: 0.5vw solid #FFB866;
            }
        }
        #cvLink:hover{
            border: 3px solid #4583A1;

            @media screen and (max-width: 620px){
                border: 0.5vw solid #4583A1;
            }
        }
        #contactLink:hover{
            border: 3px solid #49B678;

            @media screen and (max-width: 620px){
                border: 0.5vw solid #49B678;
            }
        }
    }
    
}
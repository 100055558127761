#thankYouContainer{
    min-height: 556px;
    display: flex;

    #loader, #thankYouContent{
        margin: auto;
    }

    #thankYouContent{
        display: none;
        font-size: 3.6rem;
        line-height: 1.5;

        @media screen and (max-width: 768px){
            font-size: 7vw;
        }

        @media screen and (max-width: 530px){
            font-size: 10vw;
        }
    }
}
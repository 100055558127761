#main{
    text-align: center;
    overflow: hidden;

    h1{
        font-family: kungfont;
        font-size: 8rem;
        font-weight: 100;
        margin-top: 14vh;

        @media screen and (max-width: 1200px){
            font-size: 6rem;
        }

        @media screen and (max-width: 1000px){
            margin-top: 20vh;
        }

        @media screen and (max-width: 800px){
            font-size: 11vw;
        }

        @media screen and (max-width: 600px){
            font-size: 15vw;
            margin-top: 8rem;
        }

        .titleLetter{

            display: inline-block;

            &:nth-child(1), &:nth-child(3), &:nth-child(5), &:nth-child(9), &:nth-child(11){
                font-size: 11rem;

                @media screen and (max-width: 1200px){
                    font-size: 9rem;
                }

                @media screen and (max-width: 800px){
                    font-size: 17vw;
                }

                @media screen and (max-width: 600px){
                    font-size: 22vw;
                }
            }

            &:nth-child(1){
                color: #ff8566;
                animation: letter1Anim1 0.05s ease-in-out 1s 1 backwards, letter1Anim2 0.2s ease-in-out 1.7s 1 backwards;
                
                @keyframes letter1Anim1 {
                    0%{
                        transform: translateX(-33vw);
                    }
                    100%{
                        transform: translateX(0);
                    }
                }
                
                @keyframes letter1Anim2 {
                    0%{
                        color: white;
                    }
                    100%{
                        color: #ff8566;
                    }
                }

                @media screen and (max-width: 600px){
                    @keyframes letter1Anim1 {
                        0%{
                            transform: translateX(-100vw);
                        }
                        100%{
                            transform: translateX(0);
                        }
                    }
                }

                @media screen and (min-width: 1921px){
                    animation: letter1Anim1 0.5s ease-in-out 1 backwards, letter1Anim2 1s ease-in-out 0.5s 1 backwards;

                    @keyframes letter1Anim1 {
                        0%{
                            opacity: 0;
                        }
                        100%{
                            opacity: 1;
                        }
                    }
                }
            }
            &:nth-child(2){
                animation: letter2Anim1 0.1s ease-in-out 0.9s 1 backwards;

                @keyframes letter2Anim1 {
                    0%{
                        transform: translateX(-39vw);
                    }
                    100%{
                        transform: translateX(0);
                    }
                }

                @media screen and (max-width: 600px){
                    @keyframes letter2Anim1 {
                        0%{
                            transform: translateX(-100vw);
                        }
                        100%{
                            transform: translateX(0);
                        }
                    }
                }

                @media screen and (min-width: 1921px){
                    animation: letter2Anim1 0.5s ease-in-out 1 backwards;

                    @keyframes letter2Anim1 {
                        0%{
                            opacity: 0;
                        }
                        100%{
                            opacity: 1;
                        }
                    }
                }
            }
            &:nth-child(3){
                color: #FFB866;
                animation: letter3Anim1 0.15s ease-in-out 0.75s 1 backwards, letter3Anim2 0.2s ease-in-out 1.7s 1 backwards;

                @keyframes letter3Anim1 {
                    0%{
                        transform: translateX(-44vw);
                    }
                    100%{
                        transform: translateX(0);
                    }
                }
                
                @keyframes letter3Anim2 {
                    0%{
                        color: white;
                    }
                    100%{
                        color: #FFB866;
                    }
                }

                @media screen and (max-width: 600px){
                    @keyframes letter3Anim1 {
                        0%{
                            transform: translateX(-100vw);
                        }
                        100%{
                            transform: translateX(0);
                        }
                    }
                }

                @media screen and (min-width: 1921px){
                    animation: letter3Anim1 0.5s ease-in-out 1 backwards, letter3Anim2 1s ease-in-out 0.5s 1 backwards;

                    @keyframes letter3Anim1 {
                        0%{
                            opacity: 0;
                        }
                        100%{
                            opacity: 1;
                        }
                    }
                }
            }
            &:nth-child(4){
                animation: letter4Anim1 0.2s ease-in-out 0.55s 1 backwards;

                @keyframes letter4Anim1 {
                    0%{
                        transform: translateX(-49vw);
                    }
                    100%{
                        transform: translateX(0);
                    }
                }

                @media screen and (max-width: 600px){
                    @keyframes letter4Anim1 {
                        0%{
                            transform: translateX(-100vw);
                        }
                        100%{
                            transform: translateX(0);
                        }
                    }
                }

                @media screen and (min-width: 1921px){
                    animation: letter4Anim1 0.5s ease-in-out 1 backwards;

                    @keyframes letter4Anim1 {
                        0%{
                            opacity: 0;
                        }
                        100%{
                            opacity: 1;
                        }
                    }
                }
            }
            &:nth-child(5){
                color: #4583A1;
                animation: letter5Anim1 0.25s ease-in-out 0.3s 1 backwards, letter5Anim2 0.2s ease-in-out 1.7s 1 backwards;

                @keyframes letter5Anim1 {
                    0%{
                        transform: translateX(-59vw);
                    }
                    100%{
                        transform: translateX(0);
                    }
                }
                
                @keyframes letter5Anim2 {
                    0%{
                        color: white;
                    }
                    100%{
                        color: #4583A1;
                    }
                }

                @media screen and (max-width: 600px){
                    @keyframes letter5Anim1 {
                        0%{
                            transform: translateX(-100vw);
                        }
                        100%{
                            transform: translateX(0);
                        }
                    }
                }

                @media screen and (min-width: 1921px){
                    animation: letter5Anim1 0.5s ease-in-out 1 backwards, letter5Anim2 1s ease-in-out 0.5s 1 backwards;

                    @keyframes letter5Anim1 {
                        0%{
                            opacity: 0;
                        }
                        100%{
                            opacity: 1;
                        }
                    }
                }
            }
            &:nth-child(6){
                animation: letter6Anim1 0.3s ease-in-out 1 backwards;

                @keyframes letter6Anim1 {
                    0%{
                        transform: translateX(-60vw);
                    }
                    100%{
                        transform: translateX(0);
                    }
                }

                @media screen and (max-width: 600px){
                    @keyframes letter6Anim1 {
                        0%{
                            transform: translateX(-100vw);
                        }
                        100%{
                            transform: translateX(0);
                        }
                    }
                }

                @media screen and (min-width: 1921px){
                    animation: letter6Anim1 0.5s ease-in-out 1 backwards;

                    @keyframes letter6Anim1 {
                        0%{
                            opacity: 0;
                        }
                        100%{
                            opacity: 1;
                        }
                    }
                }
            }
            
            &:nth-child(9){
                color: #49B678;
                animation: letter8Anim1 0.2s ease-in-out 1.05s 1 backwards, letter8Anim2 0.2s ease-in-out 1.7s 1 backwards;

                @keyframes letter8Anim1 {
                    0%{
                        transform: translateX(47vw);
                    }
                    100%{
                        transform: translateX(0);
                    }
                }
                
                @keyframes letter8Anim2 {
                    0%{
                        color: white;
                    }
                    100%{
                        color: #49B678;
                    }
                }

                @media screen and (max-width: 600px){
                    @keyframes letter8Anim1 {
                        0%{
                            transform: translateX(100vw);
                        }
                        100%{
                            transform: translateX(0);
                        }
                    }
                }

                @media screen and (min-width: 1921px){
                    animation: letter8Anim1 0.5s ease-in-out 1 backwards, letter8Anim2 1s ease-in-out 0.5s 1 backwards;

                    @keyframes letter8Anim1 {
                        0%{
                            opacity: 0;
                        }
                        100%{
                            opacity: 1;
                        }
                    }
                }
            }
            &:nth-child(10){
                animation: letter9Anim1 0.15s ease-in-out 1.25s 1 backwards;

                @keyframes letter9Anim1 {
                    0%{
                        transform: translateX(39vw);
                    }
                    100%{
                        transform: translateX(0);
                    }
                }

                @media screen and (max-width: 600px){
                    @keyframes letter9Anim1 {
                        0%{
                            transform: translateX(100vw);
                        }
                        100%{
                            transform: translateX(0);
                        }
                    }
                }

                @media screen and (min-width: 1921px){
                    animation: letter9Anim1 0.5s ease-in-out 1 backwards;

                    @keyframes letter9Anim1 {
                        0%{
                            opacity: 0;
                        }
                        100%{
                            opacity: 1;
                        }
                    }
                }
            }
            &:nth-child(11){
                color: #ff8566;
                animation: letter10Anim1 0.1s ease-in-out 1.4s 1 backwards, letter10Anim2 0.2s ease-in-out 1.7s 1 backwards;

                @keyframes letter10Anim1 {
                    0%{
                        transform: translateX(34vw);
                    }
                    100%{
                        transform: translateX(0);
                    }
                }
                
                @keyframes letter10Anim2 {
                    0%{
                        color: white;
                    }
                    100%{
                        color: #ff8566;
                    }
                }

                @media screen and (max-width: 600px){
                    @keyframes letter10Anim1 {
                        0%{
                            transform: translateX(100vw);
                        }
                        100%{
                            transform: translateX(0);
                        }
                    }
                }

                @media screen and (min-width: 1921px){
                    animation: letter10Anim1 0.5s ease-in-out 1 backwards, letter10Anim2 1s ease-in-out 0.5s 1 backwards;

                    @keyframes letter10Anim1 {
                        0%{
                            opacity: 0;
                        }
                        100%{
                            opacity: 1;
                        }
                    }
                }
            }
            &:nth-child(12){
                animation: letter11Anim1 0.05s ease-in-out 1.5s 1 backwards;

                @keyframes letter11Anim1 {
                    0%{
                        transform: translateX(32vw);
                    }
                    100%{
                        transform: translateX(0);
                    }
                }

                @media screen and (max-width: 600px){
                    @keyframes letter11Anim1 {
                        0%{
                            transform: translateX(100vw);
                        }
                        100%{
                            transform: translateX(0);
                        }
                    }
                }

                @media screen and (min-width: 1921px){
                    animation: letter11Anim1 0.5s ease-in-out 1 backwards;

                    @keyframes letter11Anim1 {
                        0%{
                            opacity: 0;
                        }
                        100%{
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .titleSpace{
            @media screen and (max-width: 600px){
                display: none;
            }
        }

        .titleBreak{
            display: none;

            @media screen and (max-width: 600px){
                display: initial;
            }
        }
    }


    h3{
        font-family: finland;
        font-size: 5rem;
        font-weight: 100;
        margin: 5rem 0 3rem 0;

        animation: subtitleAnim1 0.8s ease-out 2.2s 1 backwards;

        @keyframes subtitleAnim1 {
            0%{
                transform: translateY(-100vh) scale(1);
            }
            50%{
                transform: translateX(0) scale(1);
            }
            60%{
                transform: translateX(0) scale(1);
            }
            80%{
                transform: translateX(0) scale(1.2);
            }
            100%{
                transform: translateX(0) scale(1);
            }
        }

        @media screen and (min-width: 1921px){
            animation: subtitleAnim1 0.8s ease-out 1.5s 1 backwards;
        }

        @media screen and (max-width: 1200px){
            font-size: 4rem;
        }

        @media screen and (max-width: 800px){
            font-size: 7.5vw;
            margin: 8vw 0 10vw 0;
        }

        @media screen and (max-width: 600px){
            font-size: 9vw;

            // @keyframes subtitleAnim1 {
            //     0%{
            //         transform: translateY(-60vh) scale(1);
            //     }
            //     50%{
            //         transform: translateX(0) scale(1);
            //     }
            //     60%{
            //         transform: translateX(0) scale(1);
            //     }
            //     80%{
            //         transform: translateX(0) scale(1.2);
            //     }
            //     100%{
            //         transform: translateX(0) scale(1);
            //     }
            // }
        }
    }
}
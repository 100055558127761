#skillsContainer {
    text-align: center;
    margin: auto;

    #skillsIconsContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 4rem;
        margin-bottom: 6rem;

        @media screen and (max-width: 700px) {
            grid-gap: 9vw;
        }

        .skillIcon {
            // cursor: pointer;

            title {
                color: blue;
            }
        }

        svg[title]:hover::after {
            content: attr(title);
            position: absolute;
            top: -100%;
            left: 0;
        }
    }
}

#iconAnim1 {
    animation: smallToLarge 0.5s ease-out 1;

    @keyframes smallToLarge {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(1.3);
        }
    }

    transform: scale(1.3);
}

#iconAnim2 {
    animation: largeToSmall 0.5s ease-out 1;

    @keyframes largeToSmall {
        0% {
            transform: scale(1.3);
        }

        100% {
            transform: scale(1);
        }
    }

    transform: scale(1);
}
#contact{
    min-width: 650px;
    text-align: center;
    margin: auto;

    @media screen and (max-width: 900px){
        min-width: initial;
        width: 75%;
    }

    @media screen and (max-width: 800px){
        width: 80%;
    }

    @media screen and (max-width: 650px){
        width: 90%;
    }

    h1{
        font-size: 3.5rem;
        margin: 6rem 0 2rem 0;
    }

    #contactForm{
        display: grid;
        grid-gap: 10px;
        margin-bottom: 5rem;

        .contactField{
            width: 100%;
            margin: 0;

            
            &:nth-child(1){
                grid-row: 1 / -1;
                grid-column: 1 / 3;

                @media screen and (max-width: 450px){
                    grid-column: 1 / 5;
                }
            }
            &:nth-child(2){
                grid-row: 1 / -1;
                grid-column: 3 / 5;

                @media screen and (max-width: 450px){
                    grid-row: 2 / 3;
                    grid-column: 1 / 5;
                }
            }
            &:nth-child(3){
                grid-row: 2 / 4;
                grid-column: 1 / 5;

                @media screen and (max-width: 450px){
                    grid-row: 3 / 5;
                }
            }
    
            input, textarea{
                width: 100%;
                font-family: finland;
                font-size: 1.5rem;  
                font-weight: 600; 
                box-sizing: border-box; 
                padding-left: 15px; 
                     
                &::placeholder{
                    font-size: 1.5rem;
                    font-weight: 100;
                }
            }
    
            input{
                height: 50px;      
            }
    
            textarea{
                height: 180px; 
                padding-top: 15px;
                
            }
        }
    
    
        // #formSubmitLink{
        //     grid-row: 4 / 5;
        //     grid-column: 1 / 5;
        //     text-decoration: none;

        //     @media screen and (max-width: 450px){
        //         grid-row: 5 / 6;
            // }
            #formSubmitBtn{
                width: 100%;
                height: 3rem;
                grid-row: 4 / 5;
                grid-column: 1 / 5;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: finland;
                font-size: 2rem;
                font-weight: 700;
                letter-spacing: 0.3rem;
                text-decoration: none;
                color: white;
                background-color: #101010;
                border: 2px solid white;
                cursor: pointer;

                @media screen and (max-width: 450px){
                    grid-row: 5 / 6;
                }
                
        
                &:hover{
                    color: black;
                    background-color: white;
                    border: 2px solid rgb(221, 0, 49);
                }
            }
        // }

        p{
            font-size: 2rem;
            margin: auto;
        }
    }
    
}


.notValid{
    &::placeholder{
        color: red;
    }
}
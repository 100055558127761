.appContainer {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    color: white;
    position: relative;


    h4 {
        font-size: 3.8rem;
        font-weight: 600;
        margin: 5rem 0 3rem 0;

        @media screen and (max-width: 800px) {
            font-size: 7.5vw;
        }

        @media screen and (max-width: 600px) {
            font-size: 10.5vw;
        }
    }

    .custom-toast {
        width: 500px;
        font-size: 22px;
        color: #000;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        margin-top: 100px;

        @media screen and (max-width: 600px) {
            width: 90%;
        }

        img {
            width: 40%;
            height: auto;
            margin: 20px 0;
        }
    }

}

#lightMode {
    background-color: white;
    color: #101010;

    #lightSwitch .lightSwitchTitle {
        color: #101010;
    }

    #navbarContainer {
        button {
            background-color: white;
            color: #101010;

            &:hover {
                color: white;
            }
        }

        #projectsLink {
            border: 3px solid #FFB866;

            @media screen and (max-width: 620px) {
                border: 0.5vw solid #FFB866;
            }

            &:hover {
                background-color: #FFB866;
            }
        }

        #cvLink {
            border: 3px solid #4583A1;

            @media screen and (max-width: 620px) {
                border: 0.5vw solid #4583A1;
            }

            &:hover {
                background-color: #4583A1;
            }
        }

        #contactLink {
            border: 3px solid #49B678;

            @media screen and (max-width: 620px) {
                border: 0.5vw solid #49B678;
            }

            &:hover {
                background-color: #49B678;
            }
        }
    }

    .projectDescription p {
        font-weight: 700;
    }

    button {
        border: 1px solid black;
    }

    .projectContainer .projectData .projectBtns .viewSiteBtn button {
        @media screen and (max-width: 530px) {
            background-color: white;
            color: #101010;
            border: 0.5vw solid #101010;

            &:hover,
            &:active,
            &:focus {
                background-color: #4583A1;
                color: white;
            }
        }
    }

    #contact #contactForm #formSubmitBtn {
        background-color: #2DA260;
        color: white;

        &:hover {
            background-color: #148A47;
            color: #101010;
            border: 2px solid #30750B;
        }
    }

    #contactLinksContainer .contactLink {
        color: #101010;
    }

    #footerContainer {
        color: white;
    }
}
#footerContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    padding: 0.2rem 0;
    background-color: #282828;

    @media screen and (max-width: 530px){
       
    }

    @media screen and (max-width: 450px){
        font-size: 1.2rem;
        padding: 0.6rem 0;
        overflow: hidden;
    }
    

    svg{
        margin: 0 5px;
    }
}
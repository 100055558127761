
// @font-face{
//     font-family: finland;
//     src: url('./assets/fonts/finland/Finland\ Rounded\ Thin.ttf');
// }

@font-face{
    font-family: finland;
    src: url('./assets/fonts/finland/MyFont-Regular2.otf');
}

@font-face{
    font-family: kungfont;
    src: url('./assets/fonts/kungfont/Kungfont-Regular.otf');
}


*{
    margin: 0;
    padding: 0;
}

body, html{
    height: 100%;
    width: 100%;
    
    background-color: #101010;
    font-family: finland;

    
}

html {
    scroll-behavior: smooth;
}
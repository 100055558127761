#lightSwitch{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 1rem;
    top: 3rem; 

    @media screen and (max-width: 1000px){
        position: absolute;
        top: 0.5rem; 
    }

    .lightSwitchTitle{
        font-size: 1.8rem;
        color: white;
        margin: 0.5rem 0;

        @media screen and (max-width: 800px){
            font-size: 3vw;
        }

        @media screen and (max-width: 600px){
            font-size: 1.2rem;
        }
    }

    #lightSwitchContainer{
        height: 6rem;
        width: 3rem;
        background-color: #fffff3;
        display: flex;
        flex-direction: column;
        perspective: 100px; 
        border: 1px solid #101010;
        border-radius: 4px;

        @media screen and (max-width: 600px){
            height: 4rem;
        }

        .screw{
            height: 0.5rem;
            width: 0.5rem;
            border-radius: 5px;
            // background-color: black;
            background-image:     -webkit-repeating-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0)   6%, hsla(0,0%,100%, .1) 7.5%, hsla(0,0%, 100%,0) 9%),                 
                -webkit-repeating-linear-gradient(left, hsla(0,0%,  0%,0) 0%, hsla(0,0%,  0%,0)   4%, hsla(0,0%,  0%,.03) 4.5%, hsla(0,0%,  0%,0) 6%),                  
                -webkit-repeating-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0) 1.2%, hsla(0,0%,100%,.15) 2.2%, hsla(0,0%,100%,0) 4%),
                -webkit-linear-gradient(-90deg, hsl(0,0%,78%)  0%, 
                                hsl(0,0%,90%) 47%, 
                                hsl(0,0%,78%) 53%,
                                hsl(0,0%,70%)100%);
            margin: 5px auto;

            @media screen and (max-width: 600px){
                // height: 1.2vw;
                // width: 1.2vw;
                // border-radius: 1.2vw;
                // margin: 1vw auto;
                display: none;
            }
        }

        .lightSwitchModeContainer{
            margin: auto;
            transform-style: preserve-3d;
            transform: rotateX(-35deg);

            .lightSwitchMode{
                height: 1.5rem;
                width: 1.35rem;    
                position: relative;
                transform-style: preserve-3d;
                margin: auto;

                @media screen and (max-width: 600px){
                    // height: 4vw;
                    // width: 4vw;
                }
                
        
                .switchFace{
                    position: absolute;
                    height: 1.5rem;
                    width: 1.35rem; 
                    border-right: 1px solid black;
                    border-left: 1px solid black;
                    box-sizing: border-box;

                    @media screen and (max-width: 600px){
                        // height: 4.2vw;
                        // width: 4vw;
                    }
                }
        
                .switchFront{
                    display: flex;
                    transform: rotateY(0deg) translateZ(10px);
                    cursor: pointer;

                    p{
                        color: black;
                        text-align: center;
                        margin: auto;

                        @media screen and (max-width: 600px){
                            // font-size: 3vw;
                        }
                    }
                }
        
                
            }
        
            #lightSwitchTop{
                margin-bottom: 0;
                // transform: rotateX(-35deg) translateZ(10px);
        
                .switchFront{
                    background-color: lightyellow;
                    border-top: 1px solid black;
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;

                    p{
                        color: black;
                        margin-top: 2px;
                    }
                }
                .switchHidden{
                    background-color: #dcdcaa;
                    transform: rotateX(96deg) translateZ(10px);
                    border-top: 1px solid black;
                }
            }
        
            #lightSwitchBottom{
                margin-top: 0;
                transform: rotateX(35deg) translateZ(10px);
        
                .switchFront{
                    background-color: lightyellow;
                    border-bottom: 1px solid black;
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 3px;

                    p{
                        color: black;
                        margin-bottom: 2px;
                    }
                }
                .switchHidden{
                    background-color: #dcdcaa;
                    transform: rotateX(-96deg) translateZ(10px);
                    border-bottom: 1px solid black;
                }
            }
        }

        
        #lightSwitchOn{
            animation: switchOn 0.2s ease-in-out 1;
        
            @keyframes switchOn {
                0%{
                    transform: rotateX(-1deg);
                }
                100%{
                    transform: rotateX(-35deg);
                }
            }
        
            transform: rotateX(-35deg);
        }
        
        #lightSwitchOff{
            animation: switchOff 0.2s ease-in-out 1;
        
            @keyframes switchOff {
                0%{
                    transform: rotateX(-35deg);
                }
                100%{
                    transform: rotateX(-1deg);
                }
            }
        
            transform: rotateX(-1deg);
        }
    }
}
#ArrowToTopContainer{
    position: fixed;
    right: 3rem;
    bottom: 3rem;
    padding: 1rem;
    cursor: pointer;

    @media screen and (max-width: 1300px){
        right: 1rem;
    }

    @media screen and (max-width: 530px){
        right: 5vw;
        bottom: 14vw;
        padding: initial;
    }

    img{
        height: 3rem;
        width: 3rem;

        @media screen and (max-width: 530px){
            height: 5vw;
            width: 5vw;
        }
    }
}

#arrowHoverAnim1{
    animation: hoverArrow1 0.6s ease-out 1;

    @keyframes hoverArrow1  {
        0%{
            transform: scale(1);
        }
        100%{
            transform: scale(1.2);
        }
    }

    transform: scale(1.2);
}

#arrowHoverAnim2{
    animation: hoverArrow2 0.6s ease-out 1;

    @keyframes hoverArrow2  {
        0%{
            transform: scale(1.2);
        }
        100%{
            transform: scale(1);
        }
    }

    transform: scale(1);
}
.projectContainer {
    text-align: center;
    width: 70%;
    margin: auto;

    @media screen and (max-width: 1400px) {
        width: 80%;
    }

    @media screen and (max-width: 800px) {
        width: 90%;
    }

    h1 {
        font-size: 3.2rem;
        letter-spacing: 0.3rem;
        margin: 8rem 0 2.5rem 0;

        @media screen and (max-width: 1200px) {
            margin: initial;
            margin-bottom: 1rem;
        }

        @media screen and (max-width: 530px) {
            font-size: 11vw;
            letter-spacing: 0.7vw;
        }
    }

    h4 {
        @media screen and (max-width: 1200px) {
            margin: 5rem 0 4rem 0;
        }
    }

    .projectData {
        height: 100%;
        max-width: 1100px;
        display: grid;
        margin: auto;
        margin-bottom: 5rem;
        padding-bottom: 3rem;
        border-bottom: 1px solid #808080;

        .projectScreens {
            grid-row: 1/5;
            grid-column: 1/2;
            height: 300px;
            width: 650px;
            flex-shrink: 0;
            text-align: center;

            @media screen and (max-width: 1500px) {
                height: 20vw;
                width: 40vw;
            }

            @media screen and (max-width: 1200px) {
                height: 24vw;
                width: initial;
                grid-row: 1/2;
                grid-column: 1/3;
                margin: auto;
            }

            @media screen and (max-width: 530px) {
                height: 50vw;
            }


            span.hide {
                display: none;
            }

            .device {
                height: 100%;
                cursor: pointer;
            }
        }

        .projectDescription {
            grid-row: 1/4;
            grid-column: 2/3;
            font-size: 2rem;
            text-align: left;
            // padding-left: 1rem;

            @media screen and (max-width: 1200px) {
                width: 70%;
                grid-row: 2/4;
                grid-column: 1/3;
                text-align: center;
                margin: auto;
            }

            @media screen and (max-width: 1000px) {
                font-size: 3.5vw;
            }

            @media screen and (max-width: 650px) {
                font-size: 4vw;
            }

            @media screen and (max-width: 530px) {
                width: 90%;
                font-size: 5.5vw;
            }

            p {
                line-height: 1.2;

                @media screen and (max-width: 1200px) {
                    line-height: 1.7;
                }

                @media screen and (max-width: 1000px) {
                    line-height: 1.4;
                    margin: 1rem 0 0.1rem 0;
                }

                @media screen and (max-width: 650px) {
                    line-height: 1.2;
                }
            }

            .icons {
                display: flex;
                justify-content: start;
                margin-top: 1rem;

                @media screen and (max-width: 1200px) {
                    width: 60%;
                    justify-content: space-around;
                    margin: auto;
                }

                @media screen and (max-width: 800px) {
                    width: 85%;
                }

                svg {
                    margin-right: 1rem;

                    @media screen and (max-width: 1200px) {
                        margin: 2vw 0;
                    }
                }
            }
        }

        .projectBtns {
            grid-row: 4/5;
            grid-column: 2/3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0;

            @media screen and (max-width: 1200px) {
                width: 50%;
                grid-row: 4/5;
                grid-column: 1/3;
                margin: 2vw auto 0 auto;
            }

            @media screen and (max-width: 800px) {
                width: 65%;
            }

            @media screen and (max-width: 530px) {
                flex-direction: column-reverse;
            }

            .switchViewContainer {
                width: 10rem;
                display: flex;
                align-items: center;
                margin: 0;

                @media screen and (max-width: 650px) {
                    width: 9rem;
                }

                @media screen and (max-width: 530px) {
                    width: 10rem;
                }

                p {
                    font-size: 1.6rem;
                    font-weight: bold;

                    @media screen and (max-width: 800px) {
                        font-size: 3.5vw;
                    }

                    @media screen and (max-width: 530px) {
                        font-size: 4.5vw;
                    }
                }

                .switchViewBtnContainer {
                    height: 25px;
                    width: 45px;
                    border-radius: 20px;
                    border: 2px solid #5c8a8a;
                    background-color: #802000;
                    margin: auto;
                    position: relative;
                    cursor: pointer;

                    .switchViewBtn {
                        height: 25px;
                        width: 25px;
                        border: 2px solid #696969;
                        box-sizing: border-box;
                        border-radius: 50%;
                        background-color: #ffffff;
                        background-image: linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);
                        position: absolute;
                        left: 0;
                    }
                }
            }

            .viewSiteBtn {
                text-decoration: none;

                button {
                    height: 2.8rem;
                    width: 8rem;
                    font-family: finland;
                    font-size: 1.7rem;
                    font-weight: 700;
                    color: white;
                    background-color: #4583A1;
                    // border: 1px solid white;
                    border: none;
                    border-radius: 0.5rem;
                    cursor: pointer;

                    @media screen and (max-width: 800px) {
                        height: 6vw;
                        width: 17vw;
                        font-size: 3.5vw;
                        border: 0.1vw solid white;
                        border-radius: 0.5rem;
                    }

                    @media screen and (max-width: 530px) {
                        height: 10vw;
                        width: 60vw;
                        font-size: 7.5vw;
                        letter-spacing: 0.5vw;
                        background-color: #101010;
                        border: 0.5vw solid white;
                        margin: 0.5rem 0 2rem 0;

                        &:hover,
                        &:active,
                        &:focus {
                            background-color: #4583A1;
                        }
                    }

                    @media screen and (max-width: 450px) {
                        margin: 0.5rem 0 1.5rem 0;
                    }

                    &:hover {
                        background-color: #2D7090;
                    }
                }
            }
        }

        #oneBtnFlex {
            justify-content: flex-end;
        }
    }
}


#btnAnimToRight .switchViewBtn {
    animation: btnToRight 0.1s linear 1;

    @keyframes btnToRight {
        0% {
            left: 0px;
        }

        100% {
            left: 20px;
        }
    }

    left: 20px;
}

#btnAnimToLeft .switchViewBtn {
    animation: btnToLeft 0.1s linear 1;

    @keyframes btnToLeft {
        0% {
            left: 20px;
        }

        100% {
            left: 0px;
        }
    }

    left: 0px;
}


#projectHoverAnim1 {
    animation: hoverProject1 0.6s ease-out 1;

    @keyframes hoverProject1 {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(1.07);
        }
    }

    transform: scale(1.07);
}

#projectHoverAnim2 {
    animation: hoverProject2 0.6s ease-out 1;

    @keyframes hoverProject2 {
        0% {
            transform: scale(1.07);
        }

        100% {
            transform: scale(1);
        }
    }

    transform: scale(1);
}
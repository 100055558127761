#contactLinksContainer{
    width: 12rem;
    display: flex;
    justify-content: space-between;
    margin: 1rem auto;

    .contactLink{
        color: white;
        cursor: pointer;
    }
}

#contactLinkAnim1{
    animation: contactLinkScaleUp 0.2s ease-in-out 1;

    @keyframes contactLinkScaleUp {
        0%{
            transform: scale(1);
        }
        100%{
            transform: scale(1.2);
        }
    }

    transform: scale(1.2);
}

#contactLinkAnim2{
    animation: contactLinkScaleDown 0.2s ease-in-out 1;

    @keyframes contactLinkScaleDown {
        0%{
            transform: scale(1.2);
        }
        100%{
            transform: scale(1);
        }
    }

    transform: scale(1);
}